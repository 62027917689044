@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: local('Roboto'), local('Roboto-Regular'), url(/public/fonts/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
:root {
--blue: #007bff;
--indigo: #6610f2;
--purple: #6f42c1;
--pink: #e83e8c;
--red: #dc3545;
--orange: #fd7e14;
--yellow: #ffc107;
--green: #28a745;
--teal: #20c997;
--cyan: #17a2b8;
--white: #fff;
--gray: #6c757d;
--gray-dark: #343a40;
--primary: #007bff;
--secondary: #6c757d;
--success: #28a745;
--info: #17a2b8;
--warning: #ffc107;
--danger: #dc3545;
--light: #f8f9fa;
--dark: #343a40;
--breakpoint-xs: 0;
--breakpoint-sm: 576px;
--breakpoint-md: 768px;
--breakpoint-lg: 992px;
--breakpoint-xl: 1200px;
}
@font-face {
font-family: 'Roboto';
font-weight: 400;
font-style: normal;
src: local('\263A'), local('\263A'), url(/public/fonts/roboto-regular-webfont.woff2) format('woff2'),
url(/public/fonts/roboto-regular-webfont.woff) format('woff'), url(/public/fonts/roboto-regular-webfont.ttf) format('truetype');
}

@font-face {
font-family: 'Roboto';
font-weight: 500;
font-style: normal;
src: local('\263A'), local('\263A'), url(/public/fonts/roboto-medium-webfont.woff2) format('woff2'),
url(/public/fonts/roboto-medium-webfont.woff) format('woff'), url(/public/fonts/roboto-medium-webfont.ttf) format('truetype');
}
html {
font-family: sans-serif;
-webkit-text-size-adjust: 100%;
-ms-text-size-adjust: 100%;
-webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}
body {
font-size: 14px;
background-color: #f9f9f9;
margin: 0;
font-family: Roboto, Arial, sans-serif;
letter-spacing: 0;
color: #212121;
line-height: 1.4;
}
* {
margin: 0;
padding: 0;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box
}
:after, :before {
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box
}
img {
vertical-align: middle
}
hr {
margin-top: 20px;
margin-bottom: 20px;
border: 0;
border-top: 2px solid #eee;
}
ol, ul {
margin-top: 0;
margin-bottom: 10px
}
ol ol, ol ul, ul ol, ul ul {
margin-bottom: 0
}
p {
margin: 0 0 10px 0;
}
header {
position: fixed;
left: 0;
right: 0;
top: 0;
}
header+section {
margin-top: 96px;
}
footer {
background: #fff;
margin-top: 15px;
}
.clearfix::after {
display: block;
clear: both;
content: "";
}
.d-none {
display: none !important;
}
.d-inline {
display: inline !important;
}
.d-inline-block {
display: inline-block !important;
}
.d-block {
display: block !important;
}
.t-left {
text-align: left
}
.t-right {
text-align: right
}
.t-center {
text-align: center
}
.f-left {
float: left;
}
.f-right {
float: right;
}
.t-justify {
text-align: justify
}
.bg-primary {
color: #fff;
background-color: #337ab7
}
.mb-0 {
margin-bottom: 0!important;
}
.mb-5 {
margin-bottom: 5px!important;
}
.mb-10 {
margin-bottom: 10px!important;
}
.mb-30 {
margin-bottom: 30px!important;
}
.m-0 {
margin: 0!important;
}
.mt-0 {
margin-top: 0!important;
}
.mt-10 {
margin-top: 10px!important;
}
.ml-0 {
margin-left: 0!important
}
.mr-0 {
margin-right: 0!important
}
.pb-0 {
padding-bottom: 0!important;
}
.p-0 {
padding: 0!important;
}
.pt-0 {
padding-top: 0!important;
}
.pl-0 {
padding-left: 0!important
}
.pr-0 {
padding-right: 0!important
}
.mtb-5 {
margin: 5px 0;
}
.ptb-10 {
padding: 10px 0;
}
.ptb-15 {
padding: 15px 0;
}
.ptb-25 {
padding: 25px 0;
}
.pt-25 {
padding-top: 25px;
}
.pb-25 {
padding-bottom: 25px;
}
.pb-10 {
padding-bottom: 10px;
}
.ptb-30 {
padding: 30px 0;
}
.header-top-bar a {
color: #fff;
}
.header-top-bar {
/*background: #1565c0;*/
background: rgb(3, 57, 108);
}
.container {
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
width: 100%;
}
.container-fluid {
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto
}
.row {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
position: relative;
max-width: 100%;
min-height: 1px;
padding-right: 15px;
padding-left: 15px;
}
.col-1 {
-webkit-box-flex: 0;
-ms-flex: 0 0 8.333333%;
flex: 0 0 8.333333%;
max-width: 8.333333%;
}
.col-2 {
-webkit-box-flex: 0;
-ms-flex: 0 0 16.666667%;
flex: 0 0 16.666667%;
max-width: 16.666667%;
}
.col-3 {
-webkit-box-flex: 0;
-ms-flex: 0 0 25%;
flex: 0 0 25%;
max-width: 25%;
}
.col-4 {
-webkit-box-flex: 0;
-ms-flex: 0 0 33.333333%;
flex: 0 0 33.333333%;
max-width: 33.333333%;
}
.col-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 41.666667%;
flex: 0 0 41.666667%;
max-width: 41.666667%;
}
.col-6 {
-webkit-box-flex: 0;
-ms-flex: 0 0 50%;
flex: 0 0 50%;
max-width: 50%;
}
.col-7 {
-webkit-box-flex: 0;
-ms-flex: 0 0 58.333333%;
flex: 0 0 58.333333%;
max-width: 58.333333%;
}
.col-8 {
-webkit-box-flex: 0;
-ms-flex: 0 0 66.666667%;
flex: 0 0 66.666667%;
max-width: 66.666667%;
}
.col-9 {
-webkit-box-flex: 0;
-ms-flex: 0 0 75%;
flex: 0 0 75%;
max-width: 75%;
}
.col-10 {
-webkit-box-flex: 0;
-ms-flex: 0 0 83.333333%;
flex: 0 0 83.333333%;
max-width: 83.333333%;
}
.col-11 {
-webkit-box-flex: 0;
-ms-flex: 0 0 91.666667%;
flex: 0 0 91.666667%;
max-width: 91.666667%;
}
.col-12 {
-webkit-box-flex: 0;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%;
}

@media (min-width: 576px) {
.col-sm {
-ms-flex-preferred-size: 0;
flex-basis: 0;
-webkit-box-flex: 1;
-ms-flex-positive: 1;
flex-grow: 1;
max-width: 100%;
}
.col-sm-auto {
-webkit-box-flex: 0;
-ms-flex: 0 0 auto;
flex: 0 0 auto;
width: auto;
max-width: none;
}
.col-sm-1 {
-webkit-box-flex: 0;
-ms-flex: 0 0 8.333333%;
flex: 0 0 8.333333%;
max-width: 8.333333%;
}
.col-sm-2 {
-webkit-box-flex: 0;
-ms-flex: 0 0 16.666667%;
flex: 0 0 16.666667%;
max-width: 16.666667%;
}
.col-sm-3 {
-webkit-box-flex: 0;
-ms-flex: 0 0 25%;
flex: 0 0 25%;
max-width: 25%;
}
.col-sm-4 {
-webkit-box-flex: 0;
-ms-flex: 0 0 33.333333%;
flex: 0 0 33.333333%;
max-width: 33.333333%;
}
.col-sm-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 41.666667%;
flex: 0 0 41.666667%;
max-width: 41.666667%;
}
.col-sm-6 {
-webkit-box-flex: 0;
-ms-flex: 0 0 50%;
flex: 0 0 50%;
max-width: 50%;
}
.col-sm-7 {
-webkit-box-flex: 0;
-ms-flex: 0 0 58.333333%;
flex: 0 0 58.333333%;
max-width: 58.333333%;
}
.col-sm-8 {
-webkit-box-flex: 0;
-ms-flex: 0 0 66.666667%;
flex: 0 0 66.666667%;
max-width: 66.666667%;
}
.col-sm-9 {
-webkit-box-flex: 0;
-ms-flex: 0 0 75%;
flex: 0 0 75%;
max-width: 75%;
}
.col-sm-10 {
-webkit-box-flex: 0;
-ms-flex: 0 0 83.333333%;
flex: 0 0 83.333333%;
max-width: 83.333333%;
}
.col-sm-11 {
-webkit-box-flex: 0;
-ms-flex: 0 0 91.666667%;
flex: 0 0 91.666667%;
max-width: 91.666667%;
}
.col-sm-12 {
-webkit-box-flex: 0;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%;
}

}
@media (min-width: 768px) {
.col-md {
-ms-flex-preferred-size: 0;
flex-basis: 0;
-webkit-box-flex: 1;
-ms-flex-positive: 1;
flex-grow: 1;
max-width: 100%;
}
.col-md-auto {
-webkit-box-flex: 0;
-ms-flex: 0 0 auto;
flex: 0 0 auto;
width: auto;
max-width: none;
}
.col-md-1 {
-webkit-box-flex: 0;
-ms-flex: 0 0 8.333333%;
flex: 0 0 8.333333%;
max-width: 8.333333%;
}
.col-md-2 {
-webkit-box-flex: 0;
-ms-flex: 0 0 16.666667%;
flex: 0 0 16.666667%;
max-width: 16.666667%;
}
.col-md-3 {
-webkit-box-flex: 0;
-ms-flex: 0 0 25%;
flex: 0 0 25%;
max-width: 25%;
}
.col-md-4 {
-webkit-box-flex: 0;
-ms-flex: 0 0 33.333333%;
flex: 0 0 33.333333%;
max-width: 33.333333%;
}
.col-md-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 41.666667%;
flex: 0 0 41.666667%;
max-width: 41.666667%;
}
.col-md-6 {
-webkit-box-flex: 0;
-ms-flex: 0 0 50%;
flex: 0 0 50%;
max-width: 50%;
}
.col-md-7 {
-webkit-box-flex: 0;
-ms-flex: 0 0 58.333333%;
flex: 0 0 58.333333%;
max-width: 58.333333%;
}
.col-md-8 {
-webkit-box-flex: 0;
-ms-flex: 0 0 66.666667%;
flex: 0 0 66.666667%;
max-width: 66.666667%;
}
.col-md-9 {
-webkit-box-flex: 0;
-ms-flex: 0 0 75%;
flex: 0 0 75%;
max-width: 75%;
}
.col-md-10 {
-webkit-box-flex: 0;
-ms-flex: 0 0 83.333333%;
flex: 0 0 83.333333%;
max-width: 83.333333%;
}
.col-md-11 {
-webkit-box-flex: 0;
-ms-flex: 0 0 91.666667%;
flex: 0 0 91.666667%;
max-width: 91.666667%;
}
.col-md-12 {
-webkit-box-flex: 0;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%;
}

}
@media (min-width: 992px) {
.col-lg {
-ms-flex-preferred-size: 0;
flex-basis: 0;
-webkit-box-flex: 1;
-ms-flex-positive: 1;
flex-grow: 1;
max-width: 100%;
}
.col-lg-auto {
-webkit-box-flex: 0;
-ms-flex: 0 0 auto;
flex: 0 0 auto;
width: auto;
max-width: none;
}
.col-lg-1 {
-webkit-box-flex: 0;
-ms-flex: 0 0 8.333333%;
flex: 0 0 8.333333%;
max-width: 8.333333%;
}
.col-lg-2 {
-webkit-box-flex: 0;
-ms-flex: 0 0 16.666667%;
flex: 0 0 16.666667%;
max-width: 16.666667%;
}
.col-lg-3 {
-webkit-box-flex: 0;
-ms-flex: 0 0 25%;
flex: 0 0 25%;
max-width: 25%;
}
.col-lg-4 {
-webkit-box-flex: 0;
-ms-flex: 0 0 33.333333%;
flex: 0 0 33.333333%;
max-width: 33.333333%;
}
.col-lg-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 41.666667%;
flex: 0 0 41.666667%;
max-width: 41.666667%;
}
.col-lg-6 {
-webkit-box-flex: 0;
-ms-flex: 0 0 50%;
flex: 0 0 50%;
max-width: 50%;
}
.col-lg-7 {
-webkit-box-flex: 0;
-ms-flex: 0 0 58.333333%;
flex: 0 0 58.333333%;
max-width: 58.333333%;
}
.col-lg-8 {
-webkit-box-flex: 0;
-ms-flex: 0 0 66.666667%;
flex: 0 0 66.666667%;
max-width: 66.666667%;
}
.col-lg-9 {
-webkit-box-flex: 0;
-ms-flex: 0 0 75%;
flex: 0 0 75%;
max-width: 75%;
}
.col-lg-10 {
-webkit-box-flex: 0;
-ms-flex: 0 0 83.333333%;
flex: 0 0 83.333333%;
max-width: 83.333333%;
}
.col-lg-11 {
-webkit-box-flex: 0;
-ms-flex: 0 0 91.666667%;
flex: 0 0 91.666667%;
max-width: 91.666667%;
}
.col-lg-12 {
-webkit-box-flex: 0;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%;
}

}
.form-control {
display: block;
width: 100%;
padding: 0.375rem 0.75rem;
/*font-size: 1rem;*/
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: 2px;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::-ms-expand {
background-color: transparent;
border: 0;
}
.form-control:focus {
color: #495057;
background-color: #fff;
border-color: #80bdff;
outline: 0;
box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
color: #6c757d;
opacity: 1;
}
.form-control::-moz-placeholder {
color: #6c757d;
opacity: 1;
}
.form-control:-ms-input-placeholder {
color: #6c757d;
opacity: 1;
}
.form-control::-ms-input-placeholder {
color: #6c757d;
opacity: 1;
}
.form-control::placeholder {
color: #6c757d;
opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
background-color: #e9ecef;
opacity: 1;
}
.d-flex {
display: -webkit-box !important;
display: -ms-flexbox !important;
display: flex !important;
}
.d-inline-flex {
display: -webkit-inline-box !important;
display: -ms-inline-flexbox !important;
display: inline-flex !important;
}
ul.top-link {
list-style: none;
display: flex;
justify-content: space-around;
width: 100%;
}
.top-link li {
display: inline-block;
}
a {
text-decoration: none;
}
.img-fluid {
max-width: 100%;
height: auto;
}
.logo img {
max-height: 32px;
}
.v-center {
display: flex;
align-items: center;
}
.f-end {
justify-content: flex-end;
}
ul.top-link li {
margin-left: 10px;
}
.logo {
max-width: 150px;
min-width: 150px;
}
.search-wrapper {
width: 100%;
}
[name="search"] {
border: medium none;
-webkit-border-radius: 5px 0 0 5px;
-moz-border-radius: 5px 0 0 5px;
border-radius: 5px 0 0 5px;
padding: 9px;
width: calc(100% - 60px);
background: #f6f6f6;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
border-top-left-radius: 3px;
border-bottom-left-radius: 3px;
}
[name="search"]+span {
margin-left: 0px;
padding: 8px 20px 8px;
text-align: center;
background-color: #ffc627;
border-top-right-radius: 3px;
border-bottom-right-radius: 3px;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
}
.search-form {
display: inline-flex;
width: 100%;
}
[name="search"]:focus {
outline: none;
}
.nav {
padding: 0px 15px;
background: rgba(255, 255, 255, 0.99);
box-shadow: 0 1px 3px rgba(50, 50, 50, 0.4);
min-height: 37px;
}
.nav ul {
list-style: none;
margin: 0;
padding: 0;
}
.nav>ul {
display: flex;
justify-content: space-between;
}
/*menu style*/

.header-wrapper {
z-index: 99999;
/*position: relative;*/
}
.main-menu>ul>li>a {
color: #303030;
display: block;
/*padding-bottom: 3px;*/
/*text-transform: uppercase;*/
font-size: 14px;
font-weight: 500;
}

.main-menu ul>li>a, .main-menu li>ul>li a {
position: relative;
}
.main-menu>ul>li>a::before {
background: #ffbf7f none repeat scroll 0 0;
bottom: 0;
content: "";
height: 2px;
left: 0;
opacity: 0;
position: absolute;
/*transition: all 0.5s ease 0s;*/
visibility: hidden;
width: 0;
}
.main-menu ul>li:hover>a::before {
width: 100%;
opacity: 1;
visibility: visible;
}
li.dropdown{position: relative;}
.main-menu ul>li, li.dropdown > ul > li {
display: inline-block;
padding: 10px 0;
position: relative;
/*transition: all 0.3s ease 0s;*//*min-height: 37px;*/
}
.main-menu ul>li:hover>a, .main-menu ul>li>a.active {
color: #FF7F00;
}
.main-menu li>ul>li:hover>a {
color: #ff7f00;
}
.single-mega-item>li:hover>a {
color: #ff7f00;
}
/*dropdwn Style*/

.main-menu li>ul , li.dropdown>ul {
background: #ffffff none repeat scroll 0 0;
box-shadow: 0 1px 2px rgba(86, 86, 90, 0.5);
left: 0;
padding: 15px;
position: absolute;
text-align: left;
top: 100%;
transform: scaleY(0);
transform-origin: 0 0 0;
/*transition: all 0.1s ease 0s;*/
visibility: hidden;
z-index: 2;
width: 100%;
}
.main-menu li:nth-child(n+5)>ul,.main-menu li:last-child>ul {
left: auto!important;
right: 0;
}
.main-menu li>ul.single-menu {
min-width: 180px;
}
.main-menu li>ul.mega-menu.two-column {
display: none;
min-width: 320px;
max-width: 320px;
display: flex;
flex-wrap: wrap;
}
.main-menu li>ul.mega-menu.four-column,.main-menu li>ul.mega-menu.five-column {
display: none;
min-width: 640px;
/*max-width: 640px;*/
display: flex;
flex-wrap: wrap;
}
.main-menu li>ul.mega-menu.two-column li {
width: 50%;
-webkit-box-flex: 0;
-ms-flex: 0 0 50%;
flex: 0 0 50%;
max-width: 50%;
}
.main-menu li>ul.mega-menu.four-column li {
width: 25%;
-webkit-box-flex: 0;
-ms-flex: 0 0 25%;
flex: 0 0 25%;
max-width: 25%;
}
.main-menu li>ul.mega-menu.five-column li {
width: 20%;
-webkit-box-flex: 0;
-ms-flex: 0 0 20%;
flex: 0 0 20%;
max-width: 20%;
}

.main-menu li>ul>li {
display: block;
padding: 1px 0;
}
.main-menu li>ul>li a {
color: #666;
display: inline-block;
font-size: 13px;
}
.main-menu li:hover>ul,li.dropdown > ul {
transform: scale(1);
visibility: visible;
}
.main-menu li.mega-parent {
position: static;
}
.banners {
position: relative;
width: 100%;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
transition: all 0.5s ease-in-out;
/*min-height: 160px;*/
}
.banners .banner:not(.active) {
display: none;
}
.banners .banner img {
width: 100%;
max-width: 100%;
transition: all 1s ease-in-out;
}
.slide-btn-white, .slide-btn-white-hover-white:hover {
color: #000!important;
background-color: #fff!important;
width: 40px;
text-align: center;
font-size: 30px;
padding: 15px 0;
cursor: pointer;
}
.slide-btn-left {
position: absolute;
top: 50%;
left: 0%;
transform: translate(0%, -50%);
-ms-transform: translate(-0%, -50%)
}
.slide-btn-right {
position: absolute;
top: 50%;
right: 0%;
transform: translate(0%, -50%);
-ms-transform: translate(0%, -50%)
}
section div>div.section {
margin-top: 15px;
background-color: #fff;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
border-top: 1px solid #eaeaea;
padding: 15px;
}
.c-pointer {
cursor: pointer;
}
.p-name * {
color: #333;
font-size: 13px;
font-weight: 500;
}
.p-name {
/*white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;*/
}
.heading-block h2 {
font-weight: 500;
color: #666666;
font-size: 100%;
padding: 3px 0 5px;
border-bottom: 1px solid rgba(0, 0, 0, .1);
font-size: 18px;
line-height: 20px;
}
.heading-block h2 a.view-all{
color: #212121;
padding: 0 ;
font-size: 13px;
font-weight: 500;
transition: box-shadow 0.2s ease;
text-transform: uppercase;
}
.btn {
display: inline-block;
font-weight: 400;
text-align: center;
white-space: nowrap;
vertical-align: middle;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
border: 1px solid transparent;
padding: 0.375rem 0.75rem;
/*font-size: 1rem;*/
line-height: 1.5;
border-radius: 0.25rem;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.view-all {
font-size: 1rem;
font-weight: 700;
color: #ffc627;
/*background: #1565c0;*/
padding: 2px 2rem;
border-radius: 17px;
cursor: pointer;
}
.product-image::not(.hot) {
height: 160px;
width: 100%;
background-image: url('/public/images/gif/loading.gif');
background-size: 50px;
background-position: center;
background-repeat: no-repeat;
}
.hot-deals:not(.category) .product-image.hot {
height: 220px;
width: 100%;
background-image: url('/public/images/gif/loading.gif');
background-size: 50px;
background-position: center;
background-repeat: no-repeat;
}
.hot-deals > div {
margin-bottom: 30px;
}
@media (max-width: 992px) {
.hide-md-down {
display: none;
}
}
.product-image {
text-align: center;
}
.product-image img {
/*opacity: 0.4!important;*/
/*height: 100%;*/
width: auto;
margin: 0 auto;
}
footer .module-title {
font-size: 14px;
font-weight: 500;
letter-spacing: 0.5px;
margin-bottom: 6px;
color: #565656 !important;
text-transform: uppercase;
}
footer .module-body ul li a {
font-size: 12px;
line-height: 22px;
position: relative;
display: block;
font-weight: normal;
color: #565656;
}
footer p{
font-size: 12px;
line-height: 18px;
position: relative;
display: block;
font-weight: normal;
color: #565656;
margin-bottom: 3px;
}
footer ul {
list-style: none;
padding-left: 0;
}
.row.copyright {
border-top: 1px dotted #e4e4e4;
}
.si-icon{
background-image: url(https://www.alapattsupershoppe.com/public/images/social.png);
width: 31px;
height: 31px;
display: inline-block;
vertical-align: middle;
margin: 0 0 0 5px;
}
.si-icon.si-f {
background-position: -508px 0;
}
.si-icon.si-t {
background-position: -508px -33px;
}
.si-icon.si-g {
background-position: -508px -65px;
}
.si-icon.si-y {
background-position: -475px -65px;
}
img.fa {
background: #3C5B9B!important;
padding: 4px;
border-radius: 3px;
width: 30px;
}
strike {
color: #333
}
.bg-white {
background-color: #fff;
}
.ls-none {
margin: 0;
padding: 0;
list-style: none;
}
.filters ul li a {
color: #333;
}
/*.filters ul li {
margin: 5px 0;
}*/

@media(min-width: 1024px) {
.filters {
border-bottom: 2px solid #eee;    background: #f9f9f9;
/*        background: #f9f9f9;*/
}
/*.products .products {
width: calc(100% - 256px);
max-width: calc(100% - 256px);
min-width: calc(100% - 256px);
}
.products .filters {
max-width: 256px;
}*/
.products .product-image {
width: 160px;
height: 160px;
margin: 0 auto;
}
.img-fluid {
max-width: 100%;
max-height: 100%;
}
}
.breadcrumb {
color: #999;
font-weight: normal;
font-size: 12px;
}
.breadcrumb a {
margin: 0 5px;
}
.breadcrumb a:first-child {
margin-left: 0px;
color: #999;
}
.sort-by {
max-width: 200px;
float: right;
font-size: 13px;
/*text-transform: uppercase;*/
text-align: center;
}
ul.pagination {
list-style: none;
}
ul.pagination li {
display: inline-block;
width: 30px;
height: 30px;
line-height: 30px;
border-radius: 50%;
text-align: center;
}
ul.pagination li a {
padding: 5px;
text-align: center;
border-radius: 50%;
color: #333;
}
/*ul.pagination li:first-child, ul.pagination li:last-child {
font-size: 22px;
}*/
ul.pagination li:not(.active) {
/*background-color: #ccc;*/
color: #1565c0;
}
ul.pagination li.active {
background-color: #1565c0;
color: #fff;
}
.c-name span {
color: #999;
font-size: 12px;
font-weight: normal;
}
input:focus, textarea:focus, select:focus {
outline: none;
box-shadow: none!important;
border-color: #ccc;
}
.filters {
box-shadow: none
}
.products .products>div.row.products>div {
/*transition: all .3s ease;*/
margin-bottom: 30px;
padding-bottom: 15px;
position: relative;
}
.products .products>div.row.products>div:hover {
box-shadow: 0 5px 17px 0 rgba(23, 84, 116, .18);
}
.p-action img {
max-width: 18px;
margin: 0 5px;
}

.heading-block + .row {
clear: both;
}
.topspacing {
padding-top: 8px;
}
.p {
clear: both;
line-height: 1.45;
font-size: 12px;
color: #878787;
}
.cat-section {
margin: 0 auto;
padding-left: 8px;
padding-right: 8px;
max-width: 1680px;
min-width: 978px;
width: 100%;
overflow: hidden;
}
.left-sidebar {
width: 232px;
float: left;
margin: 0 10px 0 0;
}
.left-content {
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
margin-bottom: 16px;
overflow: hidden;
}
.left-inner{
margin-bottom: 30px;
padding-top: 16px;
padding-left: 16px;
}
.right-content {
float: none;
overflow: hidden;
}
li {
list-style: none;
}
ul.cat-list li {
margin: 6px 0;
}
ul.cat-list li a {
width: 100%;
display: block;
padding: 6px 0;
}
a, img {
text-decoration: none;
color: inherit;
border: none;
outline: none;
}
.category-inner-section{
background-color: #fff;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
border-radius: 2px;
margin-bottom: 15px;
}
.product-item {
padding: 15px;
position: relative;
transition: box-shadow 0.2s ease-in-out;
}
.sidebarsection {
padding: 15px;
border-bottom: 5px solid #f0f0f0;
}
.filter-titles {
font-size: 12px;
font-weight: 500;
text-transform: uppercase;
color: #212121;
margin-bottom: 5px;
}
.filter-title {
font-size: 18px;
text-transform: capitalize;
width: 67%;
letter-spacing: 0.3px;
display: inline-block;
font-weight: 500;
}
.pro-link{
position: relative;
display: block;
padding-bottom: 5px;
}
.product-img {
position: relative;
margin: 0 auto;
}
.product-img img{
position: absolute;
bottom: 0;
left: 0;
right: 0;
top: 0;
margin: auto;
opacity: 1;
max-width: 100%;
max-height: 100%;
}
.product-item .product-item {
padding-bottom: 5px;
overflow: hidden;
display: block;
}
.category-breadcrumb .breadcrumb-div {
font-size: 12px;
color: #878787;
display: inline-block;
}
.category-details {
background: #fff;
padding: 12px 16px 0 16px;
border-bottom: 1px solid #f0f0f0;
}
.category-breadcrumb .breadcrumb-arrow {
height: 7px;
width: 20px;
-webkit-transform: rotate(180deg);
transform: rotate(180deg);
vertical-align: middle;
}
.category-breadcrumb .breadcrumb-arrow-path {
fill: #878787;
}
.category-name {
font-weight: 500;
font-size: 16px;
margin-top: 8px;
display: inline-block;
}
.category-name + span {
display: inline-block;
margin-left: 10px;
color: #878787;
font-size: 12px;
}
.sort-div {
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-content: center;
-ms-flex-line-pack: center;
align-content: center;
justify-content: flex-end;
}
.sort-div .sort-by {
font-weight: 500;
padding: 8px 10px 4px 0;
border-bottom: 2px solid transparent;
display: inline-block;
}
.sort-div .sort-list {
display: inline-block;
padding: 8px 0 4px 0;
margin: 0 10px;
cursor: pointer;
border-bottom: 2px solid transparent;
}
.sort-div .sort-list.sort-active {
color: #2874f0;
cursor: default;
border-bottom: 2px solid #2874f0;
font-weight: 500;
}
.rating-div {
margin-top: 0;
display: inline-block;
padding-right: 10px;
}
.productRating{
line-height: normal;
display: inline-block;
color: #fff;
padding: 2px 4px 2px 4px;
border-radius: 3px;
background-color: #388e3c;
font-weight: 500;
font-size: 12px;
vertical-align: middle;
}
.ratingCount{
padding-left: 8px;
font-weight: 500;
color: #878787;
}
.inline-div{
display: inline-block;
vertical-align: middle;
}
.product-title {
padding-bottom: 5px;
overflow: hidden;
display: block;
}
.price-sec {
display: block;
padding: 0 10px 0 0;
margin: 3px 0;
}
.p-price{
display: inline-block;
font-size: 16px;
font-weight: 500;
color: #212121;
}
.p-strike {
text-decoration: line-through;
display: inline-block;
margin-left: 8px;
font-size: 14px;
color: #878787;
}
.p-offer {
display: inline-block;
margin-left: 8px;
color: #388e3c;
font-size: 13px;
letter-spacing: -0.2px;
font-weight: 500;
}
.p-wishlist {
position: absolute;
display: inline-block;
top: 12px;
right: 12px;
cursor: pointer;
}
.p-wishlist .wishlist-i {
position: relative;
display: -webkit-inline-flex;
display: -ms-inline-flexbox;
display: inline-flex;
}
.p-wishlist .like {
fill: #c2c2c2;
}

.p-wishlist.in .like {
fill: #1565c0;
}
.pagination-nav {
background: #fff;
border-top: 1px solid #e0e0e0;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-justify-content: space-between;
-ms-flex-pack: justify;
justify-content: space-between;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
padding: 10px;
line-height: 32px;
}
nav.pagi-ul {
padding: 4px 0;
text-align: center;
font-weight: 500;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
}
nav.pagi-ul .pagi-li {
display: inline-block;
padding: 0 8px;
cursor: pointer;
line-height: 32px;
height: 32px;
width: 32px;
border-radius: 50%;
}
nav.pagi-ul .pagi-li-active{
background: #2874f0;
color: #fff;    
}
.product-item:hover {
box-shadow: 0 3px 16px 0 rgba(0, 0, 0, .11);
}

.category-list a {
display: inline-block;
}

.arrow {
display: inline-block;
margin-right: 5px;
cursor: pointer;
}
.category-tree ul li a {
color: #212121;
}
.category-tree ul li.related-cat a {
color: #878787;
}
.category-tree ul li a {
color: #878787;
}
.category-list ul li a {
display: inline-block;
font-size: 14px;
color: #878787;
}
.category-list ul li.chosen-category a {
width: 150px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
display: inline-block;
vertical-align: top;
font-weight: 500;
color: #212121;
}
.category-tree li.category-li{
padding: 5px 0;
color: #212121;
white-space: nowrap;
}
.sub-tree li{
padding: 5px 16px 5px 30px;
color: #212121;
display: block;
white-space: nowrap;
text-overflow: ellipsis;
overflow-x: hidden;
width: 200px;
}
.category-tree .sub-tree li a {
color: #212121;
display: block;
white-space: nowrap;
text-overflow: ellipsis;
/*overflow-x: hidden;*/
/*width: 200px;*/
/*text-align: justify;*/
}
.category-tree .sub-tree li a > div{
white-space: initial;
}
svg path {
fill: #212121;
}
.related-cat svg path {
fill: #878787;
}
.product-single-image {
border: solid 1px #f0f0f0;
position: -webkit-sticky;
position: sticky;
top: 120px;
}
.category-singlepage{
background-color: #fff;
padding: 15px;
position: relative;
}
.margin-auto {
margin:0 auto;
}
.p-100 {
width: 100%;
}
.single-product .product-name {
color: #212121;
font-size: 18px;
font-weight: 400;
}
.share-btn {
float: right;
display: inline-block;
padding: 0 10px;
cursor: pointer;
border-width: 1px 0 1px 1px;
}
.product-nav {
box-sizing: border-box;
padding: 0 0 10px;
}
.single-product .rating-desc {
margin-top: 6px;
}
.price-details {
padding-top: 12px;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
}
.price-offer {
margin-left: 12px;
font-size: 16px;
font-weight: 500;
color: #388e3c;
vertical-align: middle;
display: inline-block;
letter-spacing: -0.2px;
}
.price {
font-size: 28px;
vertical-align: sub;
font-weight: 500;
color: #212121;
display: inline-block;
}
.price-strike {
font-size: 16px;
margin-left: 12px;
vertical-align: middle;
color: #878787;
text-decoration: line-through;
display: inline-block;
font-weight: 400;
}
div.my-wishlist-page{
padding: 0 10px 15px;
}
.emi-option .emi-list {
width: 100%;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-flow: row wrap;
-ms-flex-flow: row wrap;
flex-flow: row wrap;
margin-top: 12px;
}
.emi-tc {
color: #2874f0;
font-weight: 500;
display: inline-block;
margin-left: 5px;
cursor: pointer;
}
.emi-list img{
margin-right: 10px;
}
.emi-list b {
color: #212121;
font-weight: 500;
padding-right: 4px;
}
.more-emi {
border: 0;
outline: none;
background: #fff;
border-radius: 0;
font-size: 14px;
color: #2874f0;
font-weight: 500;
cursor: pointer;
margin-top: 8px;
}
.hidden {
display: none;
}
.topmargin-sm {
margin-top: 24px;
}
.option-title {
color: #878787;
width: 110px;
padding-right: 10px;
font-weight: 500;
}
.pincode-check {
width: 234px;
}
.pin-form {
padding: 0 0 4px 0;
border: none;
border-bottom: solid 2px #2874f0;
font-weight: 500;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
}
.pin-form svg {
vertical-align: middle;
margin-right: 6px;
fill: #2874f0;
}
.pincheck-form {
display: inline-block;
line-height: 25px;
}
.pincheck-form input{
font-weight: 500;
font-size: 14px;
border: none;
outline: none;
width: 180px;
padding-left: 6px;
vertical-align: middle;
font-family: Roboto, Arial, sans-serif;
letter-spacing: 0;
}
span.change-pin {
font-weight: 500;
cursor: pointer;
font-size: 14px;
color: #2874f0;
margin-left: auto;
position: relative;
}
.delivery-duration {
margin-top: 4px;
font-weight: 500;
margin-bottom: 8px;
}
.chart-list li{
display: -webkit-inline-flex;
display: -ms-inline-flexbox;
display: inline-flex;
position: relative;
margin: 0 12px 4px 0;
}
.chart-list li a {
padding: 6px 10px;
border: 2px solid #e0e0e0;
border-radius: 2px;
min-width: 36px;
position: relative;
font-size: 18px;
background: #fff;
font-weight: 500;
color: #000;
text-align: center;
}
.highlight-list li{
color: #212121;
padding: 0 0 8px 16px;
position: relative;
}
.highlight-list li:after{
content: '';
height: 6px;
width: 6px;
position: absolute;
top: 6px;
left: 0;
border-radius: 50%;
background: #c2c2c2;
}
@media (max-width: 576px){
.hide-sm{
display: none;
}
}

.search-result{
width: 100%;
min-height: 100px;
position: absolute;
background: #fff;
z-index: 99999;
margin-top: 1px;
border-radius: 2px;
background: #f6f6f6;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
font-size: 13px;
padding:10px;

}
.search-result:not(.d-none){
transform-origin: 0 0 100px;
transition: all 0.2s ease 0s;
}
.search-result p,.cursor{cursor: pointer;}

.btn {
display: inline-block;
zoom: 1;
line-height: normal;
white-space: nowrap;
vertical-align: middle;
text-align: center;
cursor: pointer;
-webkit-user-drag: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
box-sizing: border-box;
border-radius: 0;
}

.btn::-moz-focus-inner {
padding: 0;
border: 0;
}
.btn-add-to-cart{
background-color: #03396c;
color: #fff;
text-transform: uppercase;
font-size: 16px;
font-weight: 500;
transition: box-shadow 0.2s ease;
vertical-align: super;
padding: 10px 25px;
border-radius: 2px;
box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
font-family: Roboto, Arial, sans-serif;
}
.btn-buy-now{
background-color: #ffc627;
color: #fff;
text-transform: uppercase;
font-size: 16px;cursor: pointer;
font-weight: 500;
transition: box-shadow 0.2s ease;
vertical-align: super;
padding: 10px 25px;
border-radius: 2px;
box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
font-family: Roboto, Arial, sans-serif;
}
.buy-i{
margin-top: -4px;
}
.btn-add-to-cart svg path,.btn-buy-now svg path {
fill: #fff;
}
.zoomWindowContainer > div{
margin-left: 10px; 
box-shadow: 0 4px 20px 2px rgba(0, 0, 0, .2);

}
.product-thumb{
height: 50px;width: 50px;padding: 5px;border: 1px solid #999;margin-bottom: 2px;margin-left: 0;
}
.product-thumb.active{
border:1px solid #2874f0;
}
.clear-filter-sec{
display: flex;
flex-wrap: wrap;
}
.clear-filter-sec span{
background: #f1f3f6;
padding: 5px;
margin-right: 10px;
border-radius: 3px;
margin-top: 5px;
font-size: 12px;
cursor: pointer;
position: relative;
}
.clear-filter-sec span:after{
content: 'x';
position: absolute;
top: -4px;
right: 2px;
color: #ffc627;
}
.product-features{
margin-top: 24px;
border-radius: 2px;
font-size: 12px;
border: solid 1px #f0f0f0;
}
.features-title {
padding: 15px 30px 15px 24px;
font-size: 24px;
font-weight: 500;
line-height: 1.14;
color: #212121;
}
.product-feature-list{
border-top: solid 1px #f0f0f0;
padding: 24px 24px 25px 24px;
font-size: 14px;
}
.highlight-list span{
padding: 0 0 8px 16px;
position: relative;word-break: break-word;
}
.highlight-list span:after{
content: '';
height: 6px;
width: 6px;
position: absolute;
top: 6px;
left: 0;
border-radius: 50%;
background: #c2c2c2;
}
.p-15{
padding-bottom: 15px!important;
}
.table.product-specs{
width: 100%;
max-width: 100%;
border-spacing: 0;
border-collapse: collapse;
}
.product-specs .highlight-list{
margin-left: 1.75em;
}
.highlight-list p{
margin-bottom: 0;
}
.highlight-list p:after{
left: 22px;
content: '';
height: 6px;
width: 6px;
position: absolute;
top: 6px;
border-radius: 50%;
background: #c2c2c2;
}

/*MODAL START*/
.modal {
display: none; 
position: fixed; 
z-index: 1; 
/*padding-top: 100px;*/
left: 0;
top: 0;
width: 100%;
height: 100%; 
overflow: auto; 
background-color: rgb(0,0,0); 
background-color: rgba(0,0,0,0.4); 
z-index: 9999999999;
}

.modal-content {
background-color: #fefefe;
margin: auto;
padding: 0;
width: 500px;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
-webkit-animation-name: animatetop;
-webkit-animation-duration: 0.4s;
animation-name: animatetop;
animation-duration: 0.4s;
}

@-webkit-keyframes animatetop {
from {top:-300px; opacity:0} 
to {top:0; opacity:1}
}

@keyframes animatetop {
from {top:-300px; opacity:0}
to {top:0; opacity:1}
}

.close-modal {
color: #aaaaaa;
float: right;
font-size: 28px;
font-weight: bold;
}

.close-modal:hover,
.close-modal:focus {
color: #000;
text-decoration: none;
cursor: pointer;
}

/*MODAL END*/

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
margin: 0; 
}

#snackbar {
visibility: hidden;
min-width: 250px;
margin-left: -125px;
background-color: #333;
color: #fff;
text-align: center;
border-radius: 2px;
padding: 10px;
position: fixed;
z-index: 99999999;
left: 50%;
bottom: 30px;
font-size: 16px;
}

#snackbar.show {
visibility: visible;
-webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

.login-popup {
width: 760px;
box-shadow: 0 0 5px rgba(0,0,0,.3);
background-color: #fff;
min-height: 480px;
font-size: 16px;
border-radius: 8px;
overflow: hidden;
}
.modal-lg {
max-width: 760px;
border-radius: 8px;
}
.modal-md {
max-width: 460px;
border-radius: 8px;
}
/*.modal .modal-dialog {
transition: -webkit-transform .3s ease-out;
transition: transform .3s ease-out;
transition: transform .3s ease-out,-webkit-transform .3s ease-out;
-webkit-transform: translate(0,-15%);
transform: translate(0,-15%);
}*/
.modal-body {
position: relative;
-ms-flex: 1 1 auto;
flex: 1 1 auto;
padding: 1rem;
}
.modal-content {
position: relative;
display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column;
width: 100%;
pointer-events: auto;
background-color: #fff;
background-clip: padding-box;
border: 1px solid rgba(0,0,0,.2);
outline: 0;
}
.modal-dialog {
margin: 1.75rem auto;
}
.close-modal {
top: 10px;
right: 10px;
font-size: 20px;
height: 20px;
line-height: 20px;
}
.close-modal {
position: absolute;
z-index: 1;
cursor: pointer;
}
.modal-leftbox {
background: #fafafa;
min-height: 400px;
padding: 15px;
}
.modal-rightbox {
background: #fff;
min-height: 400px;

}
.loginforminner, .registerforminner, .forgotpasswordforminner,.otpverifyForm,.forgotpasswordforminner-otp{
margin: 0 5%;
}
.signu-pic{
text-align: center;
padding-top: 50px;
margin-bottom: 15px;
}
.signu-pic .login-logo {
margin-bottom: 20px;
}
.back-btn {
top: 10px;
left: 0;
font-size: 20px;
height: 20px;
line-height: 20px;
position: absolute;
z-index: 1;
cursor: pointer;
}
.modal-leftbox ul li {
position: relative;
color: #1d1d1d;
font-size: 1em;
line-height: 30px;
text-align: center;
}
.no-margin {
margin: 0 !important;
}
.no-padding {
padding: 0;
}
.no-tb-pad{
padding-top: 0px!important;padding-bottom: 0px!important
}
.mian-field-box {
padding: 30px 15px 30px;
}
.mian-field-box .form-control {
position: relative;
margin-bottom: 36px;
font-size: 16px;
padding: 8px 10px 10px 12px;
padding-right: 10px;
width: 100%;
color: #000;
font-size: inherit;
background: #fff;
border: none;
border-bottom-color: currentcolor;
border-bottom-style: none;
border-bottom-width: medium;
border-bottom: 1px solid #e0e0e0;
}
.links .rememberme {
float: left;
}
.links {
margin: 7% 0 0;
}
.links .rememberme .checkBox label {
color: #555;
font-weight: 400;
cursor: pointer;
}
.links .rememberme .checkBox input{
width: 15px;
height: 15px;
border-radius: 4px;
float: left;
margin-right: 8px;
position: relative;
margin-top: 3px;
border: 1px solid #ccc;
-webkit-transition: .1s ease-in-out;
transition: .1s ease-in-out;
}
.links .forget-password {
float: right;
color: #e71c28;
cursor: pointer;
}
.login-btn-style {
background-color: #03396c;
border-radius: 20px;
padding: 6px 25px;
height: 38px;
cursor: pointer;
color: #fff;
font-size: 16px;
font-weight: 300;
margin: 10% auto 0;
text-align: center;
vertical-align: middle;
white-space: nowrap;
width: 270px;
position: relative;
max-width: 150px;
box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
border: none;
}
.max-width220{
max-width: 220px;
}
.login-btn-style.new-account{
background: 0 0;
color: #2874f0;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
-webkit-transition: background 110ms ease-in-out;
transition: background 110ms ease-in-out;
border-radius: 20px;
margin: 0 auto 0;
}
.registrationForm .mian-field-box .form-control{
margin-bottom: 15px;
}
@-webkit-keyframes fadein {
from {bottom: 0; opacity: 0;} 
to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
from {bottom: 0; opacity: 0;}
to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
from {bottom: 30px; opacity: 1;} 
to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
from {bottom: 30px; opacity: 1;}
to {bottom: 0; opacity: 0;}
}

input.red{border-bottom:1px solid #dc3545!important;}

.my-account + ul{
width: 220px !important;
}
.my-account + ul li{margin-left: 0px!important; display: block;}
li.dropdown.my-account ul li {
display: block;
}
.my-account + ul li a{ color: #333!important;white-space: nowrap; }
li.my-account:hover > ul{
display: block!important;border-radius: 2px;min-width: 105px;    max-width: 105px;
}
.pos-rel{position: relative;}
.cart{
background: #fff;
padding-top: 30px;
padding-bottom: 30px;
}
.price-detail{
position: sticky;
top:135px;
}
h4.cart-title{
border-bottom: 1px solid #edebef;
padding-bottom: 15px;
margin-bottom: 0px;
text-transform: uppercase;
color: #212121;
font-size: 16px;
font-weight: 500;
text-align: left;
}
h4.cart-title span.total-items{
font-size: 14px;
font-weight: normal;
float: right;
font-weight: 400;
text-transform: uppercase;
}
h4.cart-title span.total-items span{
color: green;
font-size: 14px;
}

.img-responsive {
display: block;
position: relative;
width: 100%;
height: auto;
}
.row.center.cart-items{
align-items: center;
padding-top: 15px;
margin:0;
}
.product-detail span.price,.product-detail span.charge{
color: #333!important;
}


.cart-items .product-name{
text-align: left;
}
.cart-items .product-name h6{
margin-bottom: 10px;
color: #212121;
font-size: 16px;
font-weight: 400;
}
del{
color: #a59a9a;
margin-right: 10px;
}
.cart-items .product-name span.off{
color: green !important;
margin-left: 12px;
font-size: 11px;
}

.cart-items .product-price{font-size: 14px;}
.cart-items .option a{
color: #fff;
}
.cart-items .option a.add-to-cart{
margin-right: 5px;
}
.cart-items:after{
content: '';
border-bottom: 1px solid #edebef;
width: 100%;
text-align: center;
margin: 0 auto;
}
.cart-items .product-detail p{
font-size: 13px;
margin: 0;
}
.product-detail .price, .product-detail .charge{
color: green;
}
.cart-items .product-detail p span{color: #ff9800}

.cart-items .move-to-wishlist,.cart-items .remove{
background: transparent;
border: 1px solid rgb(238, 238, 238);
color: #333;
line-height: 26px;
padding: 0 7px;
display: inline-block;
text-transform: uppercase;
box-shadow: none;
height: auto;
outline: 0;
font-size: 12px;
font-weight: 400;
cursor: pointer;
margin-right: 25px;
}
.shop-bar {
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-justify-content: flex-end;
-ms-flex-pack: end;
justify-content: flex-end;
background: #fff;
border-top: 1px solid #f0f0f0;
box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, .1);
}
.co-btn svg{
height: 11px;
margin-right: 5px;
vertical-align: middle;
}
.co-btn svg path{
fill: #000;
}
.place-btn {
padding: 16px 23px;
margin: 10px 0;
}
.place-btn a{
background-color: #fff;
color: #212121;
padding: 10px 25px;
min-width: 250px;
margin-right: 6px;
text-transform: uppercase;
border-radius: 2px;
box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1);
font-size: 15px;
font-weight: 400;
}
.button.btn-buy-now{
border: 0;
}
.coupon .btn-buy-now{
font-size: 14px;
background-color: #1565c0;
}
.place-btn .po-btn {
background-color: #21376c;
color: #fff;
}
.cart-items .option{
margin:15px 0;text-align: right
}
.quantity {
float: left;
margin-right: 5px;
}
.quantity .minus{
border-bottom-left-radius: 2px;
border-top-left-radius: 2px;
}
.quantity .plus{
border-bottom-right-radius: 2px;
border-top-right-radius: 2px;
}
.quantity .plus, .quantity .minus {

float: left;
cursor: pointer;
border: 0px transparent;
padding: 0;
width: 36px;
height: 28px;
line-height: 26px;
text-align: center;
background-color: #EEE;
font-size: 16px;
font-weight: bold;
transition: background-color .2s linear;
-webkit-transition: background-color .2s linear;
-o-transition: background-color .2s linear;
}
.quantity .qty {
float: left;
width: 50px;
height: 28px;
line-height: 26px;
border: 0;font-size: 14px;
border-left: 1px solid #DDD;
border-right: 1px solid #DDD;
background-color: #EEE;
text-align: center;
margin-bottom: 0;
}
.cart-box div.col-md-4 > div{
padding-top: 15px;
border: 1px solid #edebef;
border-radius: 2px;
box-shadow: 0 0 4px rgba(40,44,63,0.08);
padding: 15px;
background: rgba(43, 58, 76, 0.01);
height: auto;
}

.price-detail h6{
border-bottom: 1px solid #edebef;
margin-bottom: 10px;
padding-bottom: 5px;
text-transform: uppercase;
color: #212121;
font-size: 14px;
font-weight: 400;
}
.line {
border-bottom: 1px solid #edebef;
margin: 10px 0;
}
.coupon .input-group{
margin-bottom: 15px;
}
.price-detail p{
font-size: 13px;
}
.price-detail p > span:last-child{
float: right;
}
.coupon .btn-secondary{
background: #ff9800;
color: #fff;
font-size: 12px;
text-transform: uppercase;
padding: 0 10px;
border: none;
}
.cart-section{
background: #fff;
}
.modal .alert{display: none;}
.modal .alert.in{
display: block;
margin-top: 40px;
margin-left: 9%;
text-align: left;
margin-bottom: -10px;
}
.alert.success{color: green;}
.alert.error,.errors{color: red;}
label.resend-otp,.resend-otp-forget{
width: 100%;
display: block;
text-align: right;
}
.empty, .page404, .success.page {
padding: 30px;
width: 100%;
background: #fff;
text-align: center;
}
.button {
display: inline-block;
position: relative;
cursor: pointer;
outline: none;
white-space: nowrap;
padding: 0 22px;
height: 38px;
line-height: 32px;
background-color: #21376c;
color: #FFF;
font-size: 0.9rem;
border: none;
border-radius: 0.1rem;
box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
}
.form-loader{
position: absolute;
width: 100%;
height: 100%;
background: #99999926;
display: flex;
align-items: center;
align-content: center;
justify-content: center;
z-index: 999999;

}
.form-loader img{
max-width: 250px;
}
.product-summary .price{
font-size: 13px!important
}

.my-account ul > li{
padding: 5px 0!important;
}
.emi-list img{
transform: rotate(135deg);
}
.checkout-box.checkout-new .checkout-steps .panel .panel-heading .unicase-checkout-title a{
padding-top: 10px;
color: #878787;
font-weight: 500;
}
.checkout-box.checkout-new .checkout-steps .panel .panel-heading .unicase-checkout-title a.collapsed{
padding-top: 0;
}
.checkout-box.checkout-new .checkout-steps .panel .panel-heading .unicase-checkout-title a span {
font-size: 12px;
color: #03396c!important;
background-color: #f0f0f0;
border-radius: 2px;
padding: 3px 7px;
vertical-align: baseline;
margin-right: 10px;
}
.checkout-edit{
padding: 0 32px;
height: 40px;
border-radius: 2px;
border: 1px solid #e0e0e0;
color: #2874f0;
font-size: 14px;
font-weight: 500;
background: #fff;
margin-left: auto;
cursor: pointer;
float: right;
text-transform: uppercase;
}
.checkout-box.checkout-new .panel-group .panel{
box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
margin-bottom: 10px;
}
.checkout-box.checkout-new .checkout-steps .panel .panel-heading {
background-color: transparent;
}
.checkout-box.checkout-new .checkout-steps .checkout-subtitle {
color: #878787;
text-transform: uppercase;
font-weight: 600;
}
.checkout-box.checkout-new .checkout-steps .guest-login ul {
padding-left: 0;
}
.checkout-box.checkout-new .register-form{
padding-top: 10px;
}
.checkout-box.checkout-new .form-control, .add-info .form-control{
height: 40px;
border-radius: 2px;
padding: 12px 16px 12px 15px;
box-shadow: none;
font-size: 14px;
}
.checkout-new .checkout-page-button, .checkout-new .btn, .add-info .btn {
background-color: #03396c;
color: #fff;
text-transform: uppercase;
font-size: 16px;
font-weight: 500;
transition: box-shadow 0.2s ease;
vertical-align: super;
padding: 10px 25px;
border-radius: 2px;
box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
font-family: Roboto, Arial, sans-serif;
border: none;
margin-top: 3px !important;
height: 38px;
}
.form-control{
font-family: Roboto, Arial, sans-serif;
}
.add-info .btn.btn-default {
background: #cbc9c9;
}
.checkout-box.checkout-new .checkout-steps .checkout-step-01 .already-registered-login .forgot-password {
font-size: 14px;
text-decoration: none;
}
.checkout-box.checkout-new .panel-body label, .add-info label{
font-size: 14px;
color: #212121;
line-height: 1.4;
font-weight: 400;
}
#butResetOTP {
margin-left: 10px;
}
.ad-edit{
margin-right: 7px;
}
.checkout-new .address-box .btn, .add-info .btn {
background-color: #ffc627;
color: #fff;
font-weight: 500;
padding: 6px 19px;
}
.payment-method label {
font-weight: 500;
}
.checkout-new .name.checkout-info-name {
margin-bottom: 5px;
color: #212121;
font-weight: 400;
}
.checkout-progress-sidebar, .checkout-progress-sidebar .cart-item.product-summary h5 {
color: #212121;
font-size: 14px !important;
font-weight: 400!important;
}
.product-summary .font-s-13 div {
display: block;
font-weight: 400;
}
.tot h5{
font-weight: 500!important;
}
.main-text {
font-size: 1.125em;
color: #acacac;
text-align: center;
width: 230px;
padding-top: 10%;
margin: 0 auto;
font-weight: 100;
}
.main-text span {
display: block;
font-size: .75em;
color: #535353;
line-height: 1.4;
padding-bottom: 10px;
}
.login-left-pos {
background: #fafafa;
}
footer .container-fluid{
width: 90%;
}
.body-content .my-wishlist-page img.buy-i {
width: auto;
}
.body-content .my-wishlist-page img.wishlist-img{
width: auto;
height: 120px;
}
.body-content .my-wishlist-page .btn:hover{
color: #fff;
}
.body-content .my-wishlist-page .btn-add-to-cart, .body-content .my-wishlist-page .btn-buy-now {
padding: 8px 25px;
}
.body-content .my-wishlist-page .my-wishlist table tbody tr:nth-child(n) {
border-bottom: 1px solid #ddd;
}
.terms p, .terms li, .terms .panel-body{
line-height: 1.5;
margin-top: 15px;
font-size: 13px;
color: #212121;
font-weight: 200;
}
.terms li, .terms .panel-body{
margin-top: 0px;
margin-bottom:3px;
}
.terms .faq-page h2 {
font-weight: 500;
color: #666666;
padding: 3px 0;
font-size: 18px;
line-height: 20px;
text-align: left;
margin: 0 0 10px;
}
.checkout-box .checkout-steps .panel .panel-heading .unicase-checkout-title a, .terms b {
font-weight: 500;
}
.r-product:hover {
box-shadow: 0 3px 16px 0 rgba(0,0,0,.11);
}
.p-specs .option-title{
width: 160px;
}
.emi-icon {
margin-right: 7px;
margin-top: 3px;
}
.form-design .form-control {
height: 40px;
border-radius: 2px;
padding: 12px 16px 12px 15px;
box-shadow: none;
font-size: 14px;
}
.form-design .btn-primary, .btn.btn-design{
background-color: #03396c;
color: #fff;
text-transform: uppercase;
font-size: 14px;
font-weight: 500;
transition: box-shadow 0.2s ease;
padding: 10px 25px;
border-radius: 2px;
box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
font-family: Roboto, Arial, sans-serif;
border: none;
margin-top: 3px !important;
height: 38px;
margin-right: 5px;
}
.form-design .btn-default {
background-color: #fff;
color: #212121;
padding: 10px 25px;
margin-right: 6px;
text-transform: uppercase;
border-radius: 2px;
box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1);
font-size: 15px;
font-weight: 400;height: 38px;
}
.form-design label {
font-weight: normal;
margin-bottom: 5px;
font-size: 13px;
}
.account-details .heading-title, .account-details h3, .account-details h2, h1.heading-title {
font-size: 18px !important;
font-weight: 500 !important;
padding-bottom: 5px !important;
margin:0px;
}
.bottommargin-xs {
margin-bottom: 10px!important;
}
.list-group-item.active-blue {
background-color: #03396C !important;
border-color: #3F3D55 !important;
color: white !important;
}
.shop-name {
font-weight: bolder;
border-bottom: 1px solid #ddd;
padding-bottom: 5px;
}
.p-wishlist.compare-btn {
right: 35px;
top:14px;
}
.p-wishlist.compare-btn.more-btn-icon {
right: 60px;
}
.p-wishlist.more-btn-icon {
top:26px;
}
.more-btn-icon {
width: 36px;
height: 36px;
border-radius: 50%;
border: 1px solid #f0f0f0;
box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
padding: 9px 12px 12px 9px;
background: #fff;
}
.order-status {
padding: 10px 20px 4px;
color: #4caf50;
font-size: 16px;
margin-bottom: 0;
}
h3.p-name.mt-0{margin-bottom: 15px;}
.recently-viewed-products{
background: #fff;
margin-top: 15px;
padding: 15px;

}
a[href="products/hot-deals"].bread-crumb{
color: #03396c;
}	
a[href="products/hot-deals"]:not(.bread-crumb){
/*font-size: 18px!important;*/
color: #fff!important;font-weight: bolder;
}
a[href="products/hot-deals"]:not(.bread-crumb):after{
content: "";
position: absolute;
top: 0px;
right: 8px;
background-image: url(http://app-int-shoppe.alapatt.co/public/images/svg/hot-deals.svg);
width: 25px;
height: 25px;
z-index: 0;
background-repeat: no-repeat;
animation: blinker 1.5s linear infinite;
}

@keyframes blinker {  
50% { opacity: 0; }
}

.ml-15{
margin-left: 15px;
}
h2.p-name *{
font-size: 18px!important;
}
.mt-30{
margin-top: 30px;
}
/*.hot-deals.category > div:not(:nth-child(4n+0)) {
border-right: 2px solid #ddd;
}*/
.hot-deals.category > div{
/*margin-top: 15px;*/
margin-bottom: 45px;
}
/*.hot-deals.category > div:nth-child(odd) {
background: #f9f9f9;
}
.hot-deals.category > div:nth-child(odd) img{
filter: brightness(98%);
}*/

.hot-deals.category > div > .product-image{
/*  -webkit-box-shadow: 0 0 1px rgb(241, 243, 246), 0 5px 17px rgba(0, 0, 0, 0.05);
box-shadow: 0 0 1px rgb(241, 243, 246), 0 5px 17px rgba(0, 0, 0, 0.05);*/
-webkit-transition: opacity .4s ease;
-o-transition: opacity .4s ease;
transition: opacity .4s ease;
padding-bottom: 30px;
background: #fff;
}

.hot-deals.category > div > .product-image  img{
filter: brightness(99%);
background: #fff;
}
.max-width-1170{
max-width: 1170px;
}

.row.hot-deals.category .col-lg-3{
-webkit-box-flex: 0;
-ms-flex: 0 0 20%;
flex: 0 0 20%;
max-width: 20%;
}

.variants .label {
display: inline-block;
background-color: #fff;
width: 100%;
border-radius: 2px;
padding: 5px;
font-size: 14px;
cursor: pointer;
color: #000;
text-align: center;
border:2px solid #999;
}

.variants  .radio:checked + .label:not(.disabled) {   
    color: blue;
    border: 2px solid blue;
    font-weight: bold;
}
.variants  .radio + .label.disabled {
	cursor: not-allowed;
	border: 2px solid #ddd;
	opacity: 0.7;
}

.variants  .row.pad-5 > div.col-sm-1{
padding-left: 5px!important;
padding-right: 5px!important;
-webkit-box-flex: 0;
-ms-flex: 0 0 10%;
flex: 0 0 10%;
max-width: 10%;
}
.variants  .row.pad-5 > div.col-sm-2 {
margin-right: 10px;
}

#myProgress {
  width: 100%;
    z-index: 999999999;
    position: fixed;
    top: 0;
}

#myBar {
  width: 1%;
  height: 3px;
  background-color: #ffc627;
}
#myProgress .blur{
	height: 100vh;opacity: 0.2;width: 100%;background: #fff;
}

.cart-items .product-name h6 span ,.name.checkout-info-name span,span.variants{
	color: green;
	font-size: 11px;display: block;font-weight: bold;margin-top: 5px;margin-bottom: 15px;
}

.product-name span{
	color: green;
    font-size: 14px;
    /*font-weight: bold;*/
}
.mar-lr-15{
	margin:0 15px;
}
.text-align-r{
	text-align: right;
}
td.bold{
	font-weight: bold;
}
td.light{
	color: #777;
}

ul.mega-menu li.top a{
	color: #1565c0;
	font-weight: bold;
	font-size: 14px;
} 
ul.mega-menu li.top{
	margin-top: 15px;
	margin-bottom: 5px;
} 

.main-menu li>ul.mega-menu li.top {
	width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
ul.mega-menu{
	padding-top: 0px!important;
}
li.static{
	position: static!important;
}